<script>
import UserServiceApi from "@/service/api/users";

export default {
  props: ["userData"],
  data() {
    return {
      amount: 0,
    };
  },
  methods: {
    submitHandler(amount, action) {
      if (amount > 0) {
        UserServiceApi.balanceAction({
          amount: amount,
          action: action,
          user_id: this.userData.id,
        })
          .then((response) => {
            if (response.data.success) {
              this.$emit("balanceUpdate",response.data.wallet);
              this.ToastMessage({
                title: "Wallet Action",
                body: `Amount ${this.amount} AED ${action==="add"?"Added":"Subtracted"}!`,
              });
            } else {
              this.ToastMessage(
                {
                  title: "Wallet Action",
                  body: "Failed!",
                },
                "danger"
              );
            }
          })
          .catch(() => {
            this.ToastMessage(
              {
                title: "Wallet Action",
                body: "Failed!",
              },
              "danger"
            );
          });
      }
    },
  },
  watch: {

  },
};
</script>

<template>
  <div class="card wallet-card">
    <div class="m-auto">
      <FormulateInput
        type="number"
        v-model="amount"
        label="Add Balance"
        placeholder="Amount here"
        validation="required|number"
        min="0"
        style="width:280px"
      />
      <div style="display:flex;justify-content: center;">
        <FormulateInput
          type="button"
          @click="submitHandler(amount, 'add')"
          :label="'Add Balance'"
          style="margin-right:5px"
        />
        <div class="subtract-balance">
          <FormulateInput
            type="button"
            @click="submitHandler(amount, 'subtract')"
            :label="'Subtract Balance'"
          />
        </div>
      </div>

      <div style="font-size:14px;font-weight:600;margin-top:30px">
        <span style="color:gray"> Wallet Balance</span> : <span v-if="userData">{{userData.balance}} </span> AED
      </div>
    </div>
  </div>
</template>

<style>
.wallet-card {
  text-align: center;
  height: 600px;
}

.wallet-card .formulate-input[data-classification="text"] input {
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  background-color: #f1f1f1;
  text-align: center;
}
.subtract-balance .formulate-input[data-classification="button"] button {
  background-color: rgb(211, 17, 40);
  border-color: rgb(211, 17, 40);
}
</style>
